import * as type from "./types";
import _ from "lodash";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_EVALUATION_PERIOD:
      return { ..._.mapKeys(action.payload, "value") };
    case type.CREATE_EVALUATION_PERIOD:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case type.EDIT_EVALUATION_DETAILS:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case type.DELETE_EVALUATION_PERIOD:
      return _.omit(state, action.payload);
    case type.DELETE_EVALUATION_DETAILS:
      return _.omit(state, action.payload);
    case type.LOCK_EVALUATION_DETAILS:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
export const EvaluationTypeDDL = (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_EVALUATION_TYPE_DDL:
      return action.payload;
    default:
      return state;
  }
};
