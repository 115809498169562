export const FETCH_EMPLOYEE_TO_PROCESS_EVALUATION =
  "FETCH_EMPLOYEE_TO_PROCESS_EVALUATION";
export const FETCH_EMPLOYEE_TO_SEND_EMAIL = "FETCH_EMPLOYEE_TO_SEND_EMAIL";
export const CREATE_PERFORMANCE_EVALUATION = "CREATE_PERFORMANCE_EVALUATION";
export const FETCH_EVALUATION_EMPLOYEES = "FETCH_PERFORMANCE_EVALUATIONS";
export const FETCH_PERFORMANCE_EVALUATION = "FETCH_PERFORMANCE_EVALUATION";
export const EDIT_PERFORMANCE_EVALUATION = "EDIT_PERFORMANCE_EVALUATION";
export const DELETE_PERFORMANCE_EVALUATION = "DELETE_PERFORMANCE_EVALUATION";
export const UPDATE_PERFORMANCE_EVALUATION_HEADER =
  "UPDATE_PERFORMANCE_EVALUATION_HEADER";
export const FETCH_EVALUATION_DETAILS = "FETCH_EVALUATION_DETAILS";
export const CREATE_EVALUATION_DETAILS = "CREATE_EVALUATION_DETAILS";
export const EDIT_EVALUATION_DETAILS = "EDIT_EVALUATION_DETAILS";
export const DELETE_EVALUATION_DETAILS = "DELETE_EVALUATION_DETAILS";
