import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_EVALUATION_METRICS:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.CREATE_EVALUATION_METRICS:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    case type.EDIT_EVALUATION_METRICS:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    case type.DELETE_EVALUATION_METRICS:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    default:
      return state;
  }
};

export const EvaluationMetrics = (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_EVALUATION_METRICS:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.CREATE_EVALUATION_METRICS:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    case type.DELETE_EVALUATION_METRICS:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    default:
      return state;
  }
};

export const DetailMetrics = (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_DETAIL_METRICS:
      return { ..._.mapKeys(action.payload, "Id") };
    default:
      return state;
  }
};
