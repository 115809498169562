import _ from "lodash";
import * as type from "./types";
export default (state = [], action) => {
  switch (action.type) {
    case type.FETCH_EMPLOYEE_TO_PROCESS_EVALUATION:
      return { ..._.mapKeys(action.payload, "key") };
    case type.FETCH_EMPLOYEE_TO_SEND_EMAIL:
      return { ..._.mapKeys(action.payload, "key") };
    // return { ...state, [action.payload]: action.payload };
    default:
      return state;
  }
};

export const EvaluationVoucher = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_EVALUATION_EMPLOYEES:
      return { ..._.mapKeys(action.payload, "EmployeeId") };
    case type.FETCH_PERFORMANCE_EVALUATION:
      return { ...state, [action.payload.Id]: action.payload };
    case type.CREATE_PERFORMANCE_EVALUATION:
      return { ...state, ..._.mapKeys(action.payload, "EmployeeId") };
    // return { ...state, [action.payload.Id]: action.payload };
    case type.EDIT_PERFORMANCE_EVALUATION:
      return { ...state, ..._.mapKeys(action.payload, "EmployeeId") };
    // return { ...state, [action.payload.Id]: action.payload };
    case type.DELETE_PERFORMANCE_EVALUATION:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};

export const EvaluationVoucherDetail = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_EVALUATION_DETAILS:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.CREATE_EVALUATION_DETAILS:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    // return { ...state, [action.payload.Id]: action.payload };
    case type.EDIT_EVALUATION_DETAILS:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    // return { ...state, [action.payload.Id]: action.payload };
    case type.DELETE_EVALUATION_DETAILS:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
